.red, .red-hover:hover, .red-active:active {
  color: #c11a33;
}

.green, .green-hover:hover, .green-active:active {
  color: #8dc63f;
}

.dark-gray, .dark-gray-hover:hover, .dark-gray-active:active {
  color: #484848;
}

.med-gray, .med-gray-hover:hover, .med-gray-active:active {
  color: #8d8d8d;
}

.light-gray, .light-gray-hover:hover, .light-gray-active:active {
  color: #ebebeb;
}

.white, .white-hover:hover, .white-active:active {
  color: #fff;
}

.black, .black-hover:hover, .black-active:active {
  color: #000;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
}

html, body {
  box-sizing: border-box;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  font-size: 17px;
}

section {
  padding: 2rem;
}

@media (max-width: 960px) {
  section {
    padding: 1rem;
  }
}

img {
  max-width: 100%;
}

.mw-100 {
  max-width: 100px;
}

.mw-300 {
  max-width: 300px;
}

.mw-600 {
  max-width: 600px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1000 {
  max-width: 1000px;
}

.mh-200, .mh-300 {
  min-height: 200px;
}

.h-100 {
  height: 100%;
}

body {
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: .5px;
  font-size: 100%;
  font-weight: normal;
}

p {
  margin: 1rem 0;
  line-height: 1.3em;
}

.bf1 {
  font-size: 4rem;
}

.bf2 {
  font-size: 2rem;
}

.bf3 {
  font-size: 1.5rem;
}

@media (max-width: 600px) {
  .bf1 {
    font-size: 1.8rem;
  }

  .bf2 {
    font-size: 1.4rem;
  }

  .bf3 {
    font-size: 1rem;
  }
}

.f1 {
  font-size: 3rem;
}

.f2 {
  font-size: 2rem;
}

.f3 {
  font-size: 1.4rem;
}

.f4 {
  font-size: 1.2rem;
}

.f5 {
  font-size: .9rem;
}

.f6 {
  font-size: .8rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.tt-none {
  text-transform: none;
}

.tt-uppercase {
  text-transform: uppercase;
}

.tt-lowercase {
  text-transform: lowercase;
}

.b {
  font-weight: bold;
}

.i {
  font-style: italic;
}

.proxima {
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.proxima-thin {
  font-family: proxima-nova, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.filson-soft {
  letter-spacing: .5px;
  font-family: filson-soft, -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

@media (max-width: 600px) {
  .text-center-sm {
    text-align: center;
  }
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-top-none {
  border-top-width: 0;
}

.border-dark-gray {
  border-color: #484848;
}

.border-med-gray {
  border-color: #8d8d8d;
}

.border-light-gray {
  border-color: #ebebeb;
}

.bg-dark-blue {
  background-color: #071f38;
}

.bg-light-blue {
  background-color: #0e87ef;
}

.bg-red {
  background-color: #c11a33;
}

.bg-green {
  background-color: #8dc63f;
}

.bg-light-green {
  background-color: #bcdd8e;
}

.bg-white {
  background-color: #fff;
}

.bg-light-gray {
  background-color: #ebebeb;
}

.bg-med-gray, .bg-dark-gray {
  background-color: #8d8d8d;
}

.bg-dark-orange {
  background-color: #f05b40;
}

.bg-black {
  background-color: #000;
}

.bg-black-80 {
  background-color: #000c;
}

.bg-black-60 {
  background-color: #0009;
}

.bg-white-50 {
  background-color: #ffffff80;
}

.bg-dark-image {
  background-color: #071f38;
  background-image: url("../../public/images/bg-dark-blue.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-hover-light-gray:hover {
  background-color: #ebebeb;
}

.btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #0000;
  padding: .3rem 1.5rem;
  font-size: .9rem;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.btn.padded {
  padding: 16px 24px;
}

.btn-lg {
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.btn-sm {
  padding: .2rem .5rem;
  font-size: 1rem;
}

.btn-red {
  color: #fff;
  background-color: #c11a33;
}

.btn-green {
  color: #fff;
  background-color: #8dc63f;
}

.btn-red-outline {
  color: #484848;
  background-color: #fff;
  border: 2px solid #c11a33;
}

.btn-dark-gray {
  color: #fff;
  background-color: #484848;
}

.btn-med-gray {
  color: #fff;
  background-color: #8d8d8d;
}

.btn-light-gray {
  color: #000;
  background-color: #ebebeb;
}

.btn-transparent-white {
  color: #fff;
  background-color: #0000;
}

.btn-transparent-black {
  color: #000;
  background-color: #0000;
}

.btn-remove {
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #c11a33;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.btn-menu-toggle {
  width: 60px;
  height: 60px;
  background: none;
  border: none;
  outline: none;
  display: none;
}

@media (max-width: 960px) {
  .btn-menu-toggle {
    z-index: 10;
    padding: .5em 1em;
    display: block;
    position: absolute;
    top: 14px;
    right: 1em;
  }
}

@media (max-width: 600px) {
  .btn-menu-toggle {
    right: 0;
  }
}

.cart {
  width: 34px;
  justify-self: end;
  text-decoration: none;
  position: relative;
}

.cart .cart-count {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -12px;
  right: -8px;
}

@media (max-width: 960px) {
  .cart {
    top: 10px;
    right: 50px;
  }
}

@media (max-width: 600px) {
  .cart {
    right: 30px;
  }
}

input[type="submit"], input[type="reset"], input[type="button"] {
  cursor: pointer;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

button {
  cursor: pointer;
}

.skip-link {
  z-index: 20;
  color: #fff;
  text-align: center;
  background: #3281dc;
  padding: .5em 1em;
  font-size: 1em;
  font-weight: bold;
  transition: top .1s linear;
  position: fixed;
  top: -30em;
  left: 0;
  right: 0;
}

.checkmarks {
  padding: 0;
  list-style-image: url("../../public/images/icons/check.png");
  position: relative;
  left: 100px;
}

.list-col-2 {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.nolist {
  padding: 0;
  list-style: none;
}

.no-underline {
  text-decoration: none;
}

.text-link {
  color: #484848;
}

.text-link:hover, .text-link:active {
  color: #c11a33;
}

.text-link:visited {
  color: #484848;
}

button, input, optgroup, select, textarea {
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

label {
  color: #8d8d8d;
  background-color: #fff;
  font-size: 1.125rem;
  transition: all .1s linear;
  top: 1.2em;
  left: 1.6em;
}

input[type="text"], input[type="tel"], input[type="password"], input[type="email"], textarea {
  color: #8d8d8d;
  width: 100%;
  border: 1px solid #8d8d8d;
  padding: .8rem;
  font-size: 1.125rem;
}

input[type="text"] + label, input[type="tel"] + label, input[type="password"] + label, input[type="email"] + label, textarea + label {
  color: #8d8d8d;
  padding: 0 .15em;
  font-size: .875rem;
  position: absolute;
  top: 0;
  left: .5em;
}

input[type="checkbox"], input[type="radio"] {
  width: 30px;
  height: 30px;
}

input[type="checkbox"] + label, input[type="radio"] + label {
  margin-left: 1rem;
}

label.radio {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

label.radio input {
  margin-right: .5rem;
}

::placeholder {
  color: #ccc;
}

.modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #000000b3;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal .modal-content {
  max-width: 800px;
  background-color: #fff;
}

.modal .modal-header {
  color: #fff;
  background-color: #0e87ef;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal .modal-body, .modal .modal-footer {
  padding: 1rem;
}

.preheader {
  height: 40px;
  z-index: 2000;
  background-color: #484848;
  justify-content: space-between;
  align-items: center;
  padding: .2rem 2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.preheader > div > * {
  margin: 0 .5rem;
}

.site-header {
  height: 106px;
  z-index: 1000;
  background-color: #fff;
  grid-template-columns: 160px 1fr 30px;
  padding: 1.5rem 2rem;
  display: grid;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0 2px 2px #0003;
  -moz-box-shadow: 0 2px 2px #0003;
  box-shadow: 0 2px 2px #0003;
}

.site-header .logo {
  z-index: 2000;
}

.site-header .logo a {
  width: 160px;
  height: 50px;
  vertical-align: top;
  display: inline-block;
}

.site-header nav {
  justify-self: center;
  align-items: center;
  display: flex;
}

.main-menu {
  padding: 0;
  list-style: none;
}

.main-menu li {
  margin: 1rem;
  display: inline-block;
}

.main-menu li a {
  text-decoration: none;
}

@media (max-width: 960px) {
  .preheader {
    justify-content: center;
    padding: .2rem;
  }

  .preheader > * {
    margin: 0 .3rem;
  }

  .site-header {
    height: 90px;
    padding: 1rem .5rem;
  }

  .site-header nav {
    flex-direction: column;
  }

  .main-menu {
    width: 100%;
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
    font-size: 2rem;
    transition: left .5s;
    display: flex;
    position: fixed;
    top: 0;
    left: 100vw;
  }

  .open .main-menu {
    left: 0;
  }
}

.header {
  height: 600px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.header .header-content {
  flex-direction: column;
  justify-content: start;
  display: flex;
  position: absolute;
  inset: 0;
}

@media (max-width: 600px) {
  .header .header-content {
    padding: 1rem;
  }
}

.partner-header {
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.partner-header img {
  max-width: 400px;
  display: block;
}

@media (max-width: 600px) {
  .header {
    height: 400px;
    background-position: top;
  }
}

#content {
  margin-top: 146px;
  display: block;
}

@media (max-width: 960px) {
  #content {
    margin-top: 130px;
  }
}

.site-footer {
  min-height: 300px;
}

.footer-links, .footer-contact {
  margin: 2rem;
}

.inner {
  max-width: 1280px;
  margin: 0 auto;
}

.inner-narrow {
  max-width: 600px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-space-between {
  justify-content: space-between;
}

.align-self-center {
  align-self: center;
}

.grid {
  display: grid;
}

.col-2 {
  grid-template-columns: 1fr 1fr;
}

.no-grid .col-2 > * {
  width: 50%;
}

.col-2-status {
  grid-template-columns: 1fr 120px;
}

.col-2-sidebar-left {
  grid-template-columns: 1fr 3fr;
}

.col-2-sidebar-right {
  grid-template-columns: 3fr 1fr;
}

.col-2-larger-left {
  grid-template-columns: 2fr 1fr;
}

.col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.col-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.col-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.gap-10 {
  grid-gap: 10px;
}

.gap-20 {
  grid-gap: 20px;
}

.gap-80 {
  grid-gap: 80px;
}

.grid-start-2 {
  grid-column: 2;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: start;
}

.grid-customer-logos {
  -ms-display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(5, 160px);
}

.grid-customer-logos img {
  max-width: 100%;
  margin: 2rem 0;
}

@media (max-width: 960px) {
  .grid-customer-logos {
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    justify-items: center;
  }
}

@media (max-width: 1200px) {
  .col-1-lg {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 960px) {
  .col-1-md {
    grid-template-columns: 1fr;
  }

  .grid-row-1-md {
    grid-row: 1;
  }

  .col-2-md {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .col-1-sm {
    grid-template-columns: 1fr;
  }

  .col-2-sm {
    grid-template-columns: 1fr 1fr;
  }
}

.shopping-cart .section-header {
  grid-template-columns: 1fr 100px 100px;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.shopping-cart .registration-form {
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.shopping-cart .cart-item {
  grid-template-columns: 1fr 100px 100px;
  align-items: center;
  display: grid;
  position: relative;
}

.shopping-cart .cart-item-desc {
  color: #484848;
  font-weight: bold;
}

.shopping-cart .cart-item-sub-desc {
  margin: .3rem 0;
  font-size: .8rem;
  font-style: italic;
  font-weight: normal;
  display: block;
}

.shopping-cart .subtotal {
  text-transform: uppercase;
  grid-template-columns: 1fr 100px 100px;
  align-items: center;
  display: grid;
}

.shopping-cart .cart-actions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 600px) {
  .shopping-cart .section-header, .shopping-cart .subtotal {
    grid-template-columns: 1fr auto;
  }

  .shopping-cart .cart-item {
    grid-template-columns: 1fr 1fr;
  }

  .shopping-cart .cart-item-action {
    text-align: left;
    grid-column: 1 / 3;
    padding: 0;
  }

  .shopping-cart .cart-actions {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.course-list {
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  justify-items: center;
  display: grid;
}

.course-tile {
  color: #000;
  max-width: 300px;
  flex-direction: column;
  margin: 1rem 1rem 2rem;
  text-decoration: none;
  display: flex;
}

.course-tile .course-topic {
  max-width: 220px;
  align-self: center;
  padding: 1rem 2rem;
}

.course-tile .course-topic img {
  max-height: 150px;
}

.course-tile .course-name {
  margin-bottom: 1rem;
}

.course-tile span {
  align-self: center;
  margin-bottom: 1rem;
}

.course-tile:hover, .course-tile:active {
  background-color: #ebebeb;
}

.course-header {
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.course-header .topic-logo {
  max-width: 140px;
  margin: 2rem;
}

.course-details {
  grid-template-columns: 400px 1fr;
  grid-template-areas: "upcoming outline"
                       "upcoming feature"
                       "upcoming audience"
                       "upcoming related";
  padding: 0;
  display: grid;
}

@media (max-width: 1200px) {
  .course-details {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "upcoming upcoming"
                         "outline outline"
                         "feature audience"
                         "related related";
  }
}

.course-details > * {
  padding: 1rem;
}

.course-details .upcoming-classes {
  grid-area: upcoming;
}

@media (max-width: 1200px) {
  .course-details .upcoming-classes ul {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    display: grid;
  }
}

.course-details .course-outline {
  grid-area: outline;
  padding: 0;
}

.course-details .course-outline .course-outline-container {
  max-width: 1400px;
  grid-gap: 40px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 2rem auto;
  display: grid;
}

@media (max-width: 1200px) {
  .course-details .course-outline .course-outline-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .course-details .course-outline .course-outline-container {
    grid-template-columns: 1fr;
  }
}

.course-details .course-outline .course-outline-container div {
  margin: 2rem;
}

.course-details .course-outline .course-outline-container div h3 {
  color: #484848;
  font-size: 1.4rem;
}

@supports (display: grid) {
  .course-details .course-outline .course-outline-container div {
    margin: 0;
  }
}

.course-details .course-outline .course-outline-container h3 {
  margin-bottom: 1rem;
  font-weight: bold;
}

.course-details .course-outline .course-outline-container:after {
  content: "";
  clear: both;
  display: table;
}

.course-details .course-feature {
  height: 320px;
  grid-area: feature;
  padding: 0;
}

.course-details .course-feature img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.course-details .course-audience {
  grid-area: audience;
}

.course-details .related-courses {
  grid-area: related;
}

.class-tile {
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "date cta"
                       "time cta"
                       "price cta";
  margin: 1rem;
  display: grid;
  position: relative;
  overflow: hidden;
}

.class-tile > * {
  display: block;
}

.class-tile .class-date {
  grid-area: date;
}

.class-tile .class-time {
  grid-area: time;
}

.class-tile .class-price {
  grid-area: price;
}

.class-tile .btn {
  grid-area: cta;
  align-self: center;
}

.class-tile .status {
  text-align: center;
  text-transform: uppercase;
  grid-area: cta;
  align-self: center;
  font-size: .9rem;
}

.class-tile .online-class {
  color: #fff;
  text-transform: lowercase;
  background-color: #fbb040;
  padding: 1.2rem 2rem .3rem;
  position: absolute;
  top: -10px;
  left: -44px;
  transform: rotate(-45deg);
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.no-grid .full-schedule span {
  width: 25%;
}

.admin-class-tile {
  color: #000;
  background-color: #ebebeb;
  border: 1px solid #0000;
  grid-template-columns: 160px 1fr auto;
  text-decoration: none;
  display: grid;
}

.admin-class-tile > * {
  padding: 1rem .5rem;
}

.admin-class-tile.selected {
  background-color: #fff;
  border-left-width: 2px;
  border-left-color: #071f38;
}

.dot {
  width: .7rem;
  height: .7rem;
  border-radius: 50%;
  margin: 2px 4px 0 0;
  display: inline-block;
}

.finance-dashboard .grid-finance {
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  display: grid;
}

.finance-dashboard .grid-quickbooks {
  grid-template-columns: 2fr 1fr 1fr;
  display: grid;
}

.finance-dashboard input {
  padding: .5rem;
}

.account-menu .selected {
  font-weight: bold;
}

.flash-messages {
  color: #fff;
  z-index: 900;
  display: block;
  position: absolute;
  top: 126px;
  left: 0;
  right: 0;
}

@media (min-width: 960px) {
  .flash-messages {
    top: 146px;
  }
}

.flash-messages .flash {
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  animation-name: show-flash;
  animation-duration: 4s;
  animation-fill-mode: both;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.flash-messages .flash-success {
  background-color: #0e87ef;
}

.flash-messages .flash-error {
  background-color: #c11a33;
}

.flash-messages .flash-text {
  text-align: center;
}

.flash-messages .flash-remove {
  background: none;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
}

@keyframes show-flash {
  0% {
    top: -40px;
  }

  20% {
    top: 0;
  }

  80% {
    top: 0;
  }

  100% {
    top: -40px;
  }
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 2rem;
}

.p-4 {
  padding: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 2rem;
}

.pt-4 {
  padding-top: 3rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 3rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 2rem;
}

.pl-4 {
  padding-left: 3rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pr-3 {
  padding-right: 2rem;
}

.pr-4 {
  padding-right: 3rem;
}

.m-header {
  margin-top: 106px;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .5rem;
}

.m-2 {
  margin: 1rem;
}

.m-3 {
  margin: 2rem;
}

.m-4 {
  margin: 3rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 3rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-3 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-4 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

@media (max-width: 600px) {
  .mb-2-sm {
    margin-bottom: 1rem;
  }
}

@media (max-width: 960px) {
  .newsletter-form {
    text-align: center;
    flex-direction: column;
  }

  .newsletter-form > * {
    margin: .5rem 0;
  }

  .newsletter-form h3 {
    padding: 0 2rem;
  }
}

@media (max-width: 1200px) {
  .checkmarks {
    max-width: 100%;
    padding: 0 0 0 2rem;
    position: static;
  }
}

@media (max-width: 960px) {
  .checkmarks {
    max-width: 400px;
    grid-template-columns: 1fr;
  }
}

.StripeElement {
  box-sizing: border-box;
  height: 60px;
  color: #8d8d8d;
  background-color: #fff;
  border: 1px solid #8d8d8d;
  padding: 20px 30px;
}

.StripeElement--invalid {
  border-color: #c11a33;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.search-results {
  flex-direction: column;
  display: flex;
}

.search-results .search-results-item {
  padding: .5rem;
}

.search-results .search-results-active {
  background-color: #ebebeb;
}

.full-width {
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  left: 50%;
  right: 50%;
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.status-red {
  color: #c11a33;
}

.status-green {
  color: #8dc63f;
}

.hidden, .bots {
  display: none;
}

@media (max-width: 960px) {
  .hidden-md {
    display: none;
  }
}

span.is-online {
  color: #fff;
  background-color: #fbb040;
  margin-left: .5rem;
  padding: .1rem .2rem;
  font-weight: normal;
  display: inline-block;
}

@media print {
  .preheader, .flash-messages, .breadcrumbs, .site-footer, .skip-link, .course-header, .upcoming-classes, .site-header {
    display: none;
  }

  #content {
    margin-top: 0;
  }

  .mb-4 {
    margin-bottom: 20px;
  }

  .section-header {
    color: #000;
    background: none;
    border: 1px solid #484848;
    font-weight: bold;
  }

  a.white, .white h2, .white p, h2.white {
    color: #000;
  }

  .grid {
    display: block;
    position: relative;
  }

  .grid.col-2 > * {
    float: left;
    display: block;
  }

  .grid.col-2 > :nth-child(2) {
    float: right;
  }

  .grid:after {
    content: "";
    clear: both;
    display: table;
  }
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  -webkit-animation: none;
  animation: none;
  display: none;
  position: absolute;
  -webkit-box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px #00000014;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
  animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid #0000;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #000000e6;
  fill: #000000e6;
  height: 28px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  cursor: pointer;
  height: 28px;
  z-index: 3;
  color: #000000e6;
  fill: #000000e6;
  padding: 10px;
  line-height: 16px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.disabled, .flatpickr-months .flatpickr-next-month.disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  -webkit-transition: fill .1s;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #39393926;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #0000001a;
}

.numInputWrapper span:active {
  background: #0003;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #39393999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #39393999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #00000080;
}

.numInputWrapper:hover {
  background: #0000000d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 28px;
  text-align: center;
  padding: 6.16px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #0000000d;
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #000000e6;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #000000e6;
}

.flatpickr-current-month input.cur-year {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #00000080;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #0000008a;
  text-align: center;
  background: none;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 #e6e6e6;
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  -ms-flex-preferred-size: 14.2857%;
  -webkit-flex-basis: 14.2857%;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 #569ff7;
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #3939394d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.disabled, .flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: #3939391a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
  display: inline-block;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 #e6e6e6;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: #3939394d;
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
  font-weight: bold;
  display: inline-block;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #f3f3f3;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=styles.css.map */
